import React, { useState, useEffect } from 'react';
import { Controller, FieldValues, FormState } from 'react-hook-form';
import Condition from 'types/Condition';
import { TextInput } from '../inputs';
import ConditionCheck from '../inputs/ConditionCheck';
import isEmpty from 'lodash/isEmpty';
import FailureMessage from './FailureMessage';

interface CreatePasswordProps {
    initialConditions: Condition[];
    formState: FormState<FieldValues>;
}

const CreatePassword = ({
    initialConditions,
    formState,
}: CreatePasswordProps) => {
    const [conditions, setConditions] =
        useState<Condition[]>(initialConditions);

    const { errors, isSubmitted } = formState;
    const invalidPasswordError = errors['password'];
    const passwordMismatchError = errors['confirmedPassword'];

    const checkConditions = () => {
        const errorsTypes = errors['password']?.types;
        if (errorsTypes) {
            const errorNames = Object.keys(errorsTypes);
            const _conditions = conditions.map((condition) =>
                errorNames.includes(condition.name)
                    ? { ...condition, checked: false }
                    : { ...condition, checked: true }
            );
            setConditions(_conditions);
        } else {
            const _conditions = conditions.map((condition) => ({
                ...condition,
                checked: true,
            }));
            setConditions(_conditions);
        }
    };

    useEffect(() => {
        checkConditions();
    }, [formState]);

    return (
        <>
            <Controller
                name="password"
                render={({ field }) => (
                    <TextInput
                        label="Password"
                        type="password"
                        placeholder="Enter Password"
                        invalid={isSubmitted && !isEmpty(invalidPasswordError)}
                        {...field}
                    />
                )}
            />
            <p style={{ color: 'gray' }}>Your password must include:</p>
            <ConditionCheck conditions={conditions} />
            {isSubmitted && invalidPasswordError && (
                <FailureMessage
                    message={invalidPasswordError.message?.toString()}
                />
            )}
            <Controller
                name="confirmedPassword"
                render={({ field }) => (
                    <TextInput
                        label="Confirm Password"
                        type="password"
                        placeholder="Enter Password Again"
                        invalid={isSubmitted && !isEmpty(passwordMismatchError)}
                        {...field}
                    />
                )}
            />
            {isSubmitted && !invalidPasswordError && passwordMismatchError && (
                <FailureMessage
                    message={passwordMismatchError.message?.toString()}
                />
            )}
        </>
    );
};

export default CreatePassword;
