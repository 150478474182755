import React from 'react';
import { FieldErrors } from 'react-hook-form/dist/types';
import { FormGroup, Input, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import './index.scss';

interface DatePickerProps {
    name: string;
    label: string;
    value: string;
    placeholder: string;
    errors: FieldErrors;
    max: string;
    required?: boolean;
    isMobile?: boolean;
    invalid?: boolean;
}

const DatePicker = ({
    name,
    label,
    value,
    required,
    invalid = false,
    isMobile = false,
    placeholder,
    errors,
    ...rest
}: Partial<DatePickerProps>) => {
    return (
        <FormGroup className="input">
            <Label>
                {label}
                <span className="required-marker">{required && '*'}</span>
            </Label>
            {isMobile ? (
                <InputMask
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    mask="99/99/9999"
                    {...rest}
                >
                    {(inputProps) => (
                        <Input
                            {...inputProps}
                            type="text"
                            invalid={invalid || !!errors?.[`${name}`]?.message}
                        />
                    )}
                </InputMask>
            ) : (
                <Input type="date" name={name} {...rest} />
            )}
        </FormGroup>
    );
};
export default DatePicker;
