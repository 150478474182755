import React from 'react';
import './index.scss';

interface ResetNotificationProps {
    onComplete?: (any) => void;
    state: any;
}
const ActivationNotification = ({ state }: Partial<ResetNotificationProps>) => {
    return (
        <div>
            <h1 className="section-header text-center">
                Request New Activation Link
            </h1>
            <hr />
            <div
                id="action-container"
                className="d-flex justify-content-center"
            >
                <p className="text-center">
                    A new activation link was sent to your email. Please check
                    your email and follow the instructions to activate your
                    account.
                </p>
            </div>
        </div>
    );
};

export default ActivationNotification;
