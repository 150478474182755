import React from 'react';
import './index.scss';

const EmailVerification = () => {
    return (
        <div className="text-center">
            <h1 className="section-header text-center">Confirm Your Email</h1>
            <hr />
            <h2 className="sub-header initial-header text-navy">
                <p>Almost Done! Before you can access your account:</p>
            </h2>
            <p className="text-navy">
                For security purposes, we have sent you a confirmation email
                containing a verification link. When you receive the email,
                click on the link and log into your account using your email and
                password you just created. <br />
                The link is valid for the next 72 hours, past that time you will
                have to request a new one be sent to you.
            </p>
        </div>
    );
};
export default EmailVerification;
