import * as yup from 'yup';

const INVALID_ERROR =
    'The specified password does not comply with password requirements. Please provide a different password';

const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const yupEmailValidation = yup
    .string()
    .matches(emailRegex, 'Please enter a valid email')
    .required('Please enter a valid email');

const yupPasswordValidation = yup
    .string()
    .required(INVALID_ERROR)
    // using matches instead of min/max to supply a custom type for condition checks
    .matches(/^.{8,16}$/, { name: 'length', message: INVALID_ERROR })
    .matches(/[a-z]+/, { name: 'lowercase', message: INVALID_ERROR })
    .matches(/[A-Z]+/, { name: 'uppercase', message: INVALID_ERROR })
    .matches(/\d+/, { name: 'number', message: INVALID_ERROR })
    .matches(/[!@#$%^&*\-_+=[\]{}|\\:',?/`~();.]+/, {
        name: 'symbol',
        message: INVALID_ERROR,
    });

const passwordSchema = yup
    .object({
        password: yupPasswordValidation,
        confirmedPassword: yup.string().when('password', {
            is: (password) => Boolean(password),
            then: (schema) =>
                schema.oneOf(
                    [yup.ref('password')],
                    'The passwords you entered do not match. Please reenter your password and try again.'
                ),
        }),
    })
    .required();

export {
    passwordSchema,
    yupEmailValidation,
    yupPasswordValidation,
    INVALID_ERROR,
};
