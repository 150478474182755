import {
    faCircleXmark,
    faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Condition from 'types/Condition';
import './ConditionCheck.scss';

interface ConditionCheckProps {
    conditions: Condition[];
}

const ConditionCheck = ({ conditions }: ConditionCheckProps) => {
    return (
        <ul className="condition-check">
            {conditions.map((condition) => (
                <li key={condition.name}>
                    <FontAwesomeIcon
                        icon={condition.checked ? faCircleCheck : faCircleXmark}
                        color={condition.checked ? 'green' : 'red'}
                        size="sm"
                        className="check-icon"
                    />
                    <span>{condition.label}</span>
                </li>
            ))}
        </ul>
    );
};

export default ConditionCheck;
