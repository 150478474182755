import React, { ReactNode } from 'react';
import './FormContainer.scss';
const logo = require('../../../assets/img/AHP_Logo_Registration.png');
const whiteLogo = require('../../../assets/img/AHP_Logo_Login.png');
interface FormContainerProps {
    variant?: 'white' | 'default';
    children?: ReactNode;
}
const FormContainer = ({
    children,
    variant = 'default',
}: FormContainerProps) => {
    if (variant === 'white') {
        return (
            <div id="form-container" className="bg-white">
                <img src={whiteLogo} alt="AHP Logo" />
                <div>{children}</div>
            </div>
        );
    }
    return (
        <div id="form-container">
            <img src={logo} alt="AHP Logo" />
            <div id="form-content">{children}</div>
        </div>
    );
};
export default FormContainer;
