import { ErrorCode } from 'constants/ErrorCodes';

const getAccountErrorMessage = (errorCode: number) => {
    switch (errorCode) {
        case ErrorCode.NO_MATCH:
            return 'The details you entered do not match our records. Please double check your information and try again.';
        case ErrorCode.EMAIL_INUSE:
            return 'The email address you entered is already registered to another member ID. Please provide a different email address.';
        case ErrorCode.MEMBERID_EXISTS:
            return 'The member ID and details you entered do not match our records. Please double check your information and try again.';
        default:
            return 'There was an unexpected error while validating your details. You may try again or contact support.';
    }
};

export { getAccountErrorMessage };
