import { ResetPasswordRequest } from './interface';
import { isMobile } from 'react-device-detect';

const URL_ENDPOINTS = {
    FORGOT_PASSWORD: 'api/PasswordReset/ForgotPassword',
    RESET_PASSWORD: 'api/PasswordReset/ResetPassword',
    VALIDATE_TOKEN: 'api/PasswordReset/ValidateToken',
    ACTIVATION_LINK: 'api/EmailVerification/RequestNewVerification',
};
const headers = { 'Content-Type': 'application/json' };
const getSearchParamsString = (obj): string =>
    new URLSearchParams(obj).toString();

const verifyRegisteredEmailAsync = async (request: {
    emailAddress: string;
}) => {
    const response = fetch(URL_ENDPOINTS.FORGOT_PASSWORD, {
        method: 'POST',
        headers: {
            ...headers,
            ...(isMobile ? {'X-mobile': isMobile}: {}),
        } as unknown as HeadersInit,
        body: JSON.stringify(request),
    }).then((response) => response.json());
    return response;
};
const validateResetTokenAsync = async (token: string) => {
    const url = `${URL_ENDPOINTS.VALIDATE_TOKEN}?${getSearchParamsString({
        token,
    })}`;
    const response = fetch(url, {
        method: 'GET',
        headers,
    }).then((response) => response.json());
    return response;
};
const resetPasswordAsync = async (request: ResetPasswordRequest) => {
    const response = fetch(URL_ENDPOINTS.RESET_PASSWORD, {
        method: 'POST',
        headers,
        body: JSON.stringify(request),
    }).then((response) => response.json());
    return response;
};

const requestNewVerificationLink = async (request: {
    emailAddress: string;
}) => {
    const response = fetch(URL_ENDPOINTS.ACTIVATION_LINK, {
        method: 'POST',
        headers,
        body: JSON.stringify(request),
    }).then((response) => response.json());
    return response;
};

export {
    verifyRegisteredEmailAsync,
    resetPasswordAsync,
    validateResetTokenAsync,
    requestNewVerificationLink,
};
