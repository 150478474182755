import React from 'react';
const MemberIdCardImg = () => {
    const memberIdCard =
        require('../../../assets/img/MemberID_Card.png');
    return <img
    style={{ objectFit: 'contain', width: '105%', height: 'auto' }}
    src={memberIdCard}
    alt="member id"
/>;
};
export default MemberIdCardImg;
