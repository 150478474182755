import { useConfigContext } from 'contexts/ConfigContext';
import React from 'react';
import { Button } from 'reactstrap';
import './index.scss';

interface ResetConfirmationProps {
    onComplete?: (any) => void;
    state: any;
}
const ResetConfirmation = ({ state }: Partial<ResetConfirmationProps>) => {
    const { mobileAppLinks, externalLinks } = useConfigContext();
    const routeChange = () => {
        const userAgent = navigator.userAgent;
        let targetUrl = externalLinks?.memberLoginDirect;
        if (/android/i.test(userAgent)) {
            targetUrl = mobileAppLinks?.androidAppLaunchLink;
        }
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            targetUrl = mobileAppLinks?.appleAppLaunchLink;
        }

        window.location.href = targetUrl ?? '';
    };
    return (
        <div>
            <h1 className="section-header text-center">
                Reset Account Password
            </h1>
            <hr />
            <div className="d-flex align-items-center justify-content-center flex-column">
                <p className="text-center">
                    You have successfully reset your password. You can now login
                    to your account.
                </p>
                <Button
                    className="next-button w-50"
                    color="primary"
                    onClick={routeChange}
                >
                    Login
                </Button>
            </div>
        </div>
    );
};

export default ResetConfirmation;
