import React, { useState, useEffect } from 'react';
import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Spinner } from 'reactstrap';
import { Checkbox, TextInput } from '../inputs';
import './index.scss';
import { registerMemberAccountAsync } from 'api/registration';
import { passwordSchema, yupEmailValidation } from '../util/validation';
import FailureMessage from '../common/FailureMessage';
import { useConfigContext } from 'contexts/ConfigContext';
import CreatePassword from '../common/CreatePassword';
import { initialConditions } from 'constants/conditions';
import { RegistrationResponse } from 'types/Response';
import { getAccountErrorMessage } from '../util/error';
import { ErrorCode } from 'constants/ErrorCodes';
import isEmpty from 'lodash/isEmpty';
import { ResponseError } from 'types/ResponseError';

const schema = yup
    .object({
        email: yupEmailValidation,
        hasAgreedToTerms: yup
            .bool()
            .oneOf([true], 'Field must be checked')
            .required(),
    })
    .concat(passwordSchema)
    .required();

type AccountSetupValues = {
    email: string;
    password: string;
    confirmedPassword: string;
    hasAgreedToTerms: boolean;
};

const defaultValues: AccountSetupValues = {
    email: '',
    password: '',
    confirmedPassword: '',
    hasAgreedToTerms: false,
};

interface AccountSetupProps {
    onComplete?: (any) => void;
    onBack: (any) => void;
    state: any;
}

const AccountSetup = ({
    onComplete,
    onBack,
    state,
}: Partial<AccountSetupProps>) => {
    const [responseError, setResponseError] = useState<ResponseError>();
    const [showError, setShowError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { externalLinks } = useConfigContext();
    const { handleSubmit, formState, trigger } = useFormContext();
    const { errors } = formState;

    const onSubmit = (data) => {
        setIsLoading(true);
        registerMemberAccountAsync({ ...state, ...data })
            .then((response: RegistrationResponse) => {
                if (response.isSuccess) {
                    onComplete && onComplete(data);
                } else {
                    const errorMessage = getAccountErrorMessage(response.code);
                    setResponseError({
                        message: errorMessage,
                        code: response.code,
                    });
                    setShowError(true);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        trigger(['password', 'hasAgreedToTerms']);
    }, []);

    return (
        <div id="member-verification">
            <h1 className="section-header text-center">
                Register your member account
            </h1>
            <hr />
            <h2 className="sub-header initial-header">
                <p>Hello {state?.firstName}, It's nice to meet you!</p>
                <p>
                    Let's setup your Login credentials for your Alignment member
                    account:
                </p>
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="email"
                    render={({ field }) => (
                        <TextInput
                            label="Email Address"
                            type="email"
                            placeholder="Enter Email Address"
                            errors={errors}
                            {...field}
                        />
                    )}
                />
                {responseError?.code === ErrorCode.EMAIL_INUSE && (
                    <FailureMessage
                        message={responseError.message}
                        isOpen={showError}
                        toggle={() => setShowError(false)}
                    />
                )}
                <CreatePassword
                    initialConditions={initialConditions}
                    formState={formState}
                />
                <div className="d-flex flex-column align-items-center ">
                    <Controller
                        name="hasAgreedToTerms"
                        render={({ field }) => (
                            <Checkbox
                                label={
                                    <span className="attestation-label">
                                        I agree to the{' '}
                                        <a
                                            href={externalLinks?.termsOfUse}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Terms of Service
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href={externalLinks?.privacyPolicy}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </span>
                                }
                                {...field}
                            />
                        )}
                    />
                    <div className="d-flex justify-content-around w-100">
                        <Button
                            className="back-button w-25"
                            color="primary"
                            onClick={onBack}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            className="next-button w-25"
                            color="primary"
                            disabled={
                                !isEmpty(errors['hasAgreedToTerms']) ||
                                isLoading
                            }
                        >
                            Finish
                            {isLoading && <Spinner size="sm" color="light" />}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const AccountSetupWrapper = (props: Partial<AccountSetupProps>) => {
    const methods = useForm<AccountSetupValues>({
        defaultValues,
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(schema, { abortEarly: false }),
    });

    return (
        <FormProvider {...methods}>
            <AccountSetup {...props} />
        </FormProvider>
    );
};

export default AccountSetupWrapper;
