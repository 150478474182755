import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { Alert, AlertProps } from 'reactstrap';

interface FailureMessageProps extends AlertProps {
    message?: string | ReactNode;
}

const FailureMessage = ({ message, ...rest }: FailureMessageProps) => (
    <Alert color="danger" className="d-flex gap-2 align-items-center" {...rest}>
        <FontAwesomeIcon icon={faCircleMinus} color="alert-danger" />
        <span>
            {message ??
                'There was an unexpected error while validating your details. You may try again or contact support.'}
        </span>
    </Alert>
);
export { FailureMessage as default };
export type { FailureMessageProps };
