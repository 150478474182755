// The max int value in C#
const MAX_INT_VALUE = 2147483647;

enum ErrorCode {
    NO_MATCH = MAX_INT_VALUE,
    EMAIL_INUSE = MAX_INT_VALUE - 2,
    MEMBERID_EXISTS = MAX_INT_VALUE - 3,
}

export { ErrorCode };
