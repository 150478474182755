import { MemberInfoRequest, CreateAccountRequest } from './interface';

const URL_ENDPOINTS = {
    VALIDATE_MEMBER: 'api/Register/ValidateMember',
    CREATE_ACCOUNT: 'api/Register/CreateAccount',
    VERIFY_EMAIL: 'api/EmailVerification/Verify',
    FORGOT_PASSWORD: 'api/PasswordReset/ForgotPassword',
    RESET_PASSWORD: 'api/PasswordReset/ResetPassword',
};
const headers = { 'Content-Type': 'application/json' };
const getSearchParamsString = (obj): string =>
    new URLSearchParams(obj).toString();

const validateMemberInfoAsync = async (memberInfo: MemberInfoRequest) => {
    const response = fetch(URL_ENDPOINTS.VALIDATE_MEMBER, {
        method: 'POST',
        headers,
        body: JSON.stringify(memberInfo),
    }).then((response) => response.json());
    return response;
};
const verifyMemberEmailAsync = async (token: string) => {
    const url = `${URL_ENDPOINTS.VERIFY_EMAIL}?${getSearchParamsString({
        token,
    })}`;
    const response = fetch(url, {
        method: 'GET',
        headers,
    }).then((response) => response.json());
    return response;
};
const registerMemberAccountAsync = async (request: CreateAccountRequest) => {
    const response = fetch(URL_ENDPOINTS.CREATE_ACCOUNT, {
        method: 'POST',
        headers,
        body: JSON.stringify(request),
    }).then((response) => response.json());
    return response;
};
export {
    validateMemberInfoAsync,
    verifyMemberEmailAsync,
    registerMemberAccountAsync,
};
