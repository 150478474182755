import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Spinner } from 'reactstrap';
import { Checkbox, TextInput, DatePicker } from '../inputs';
import './index.scss';
import { validateMemberInfoAsync } from 'api/registration';
import { useConfigContext } from 'contexts/ConfigContext';
import { ErrorCode } from 'constants/ErrorCodes';
import { getAccountErrorMessage } from '../util/error';
import { RegistrationResponse } from 'types/Response';
import FailureMessage from '../common/FailureMessage';
import { ResponseError } from 'types/ResponseError';
import { TOOLTIP_REGISTER_MESSAGES } from '../common/constants/messages';
import MemberIdCardImg from '../common/MemberIdCardImg';
import { isMobile } from 'react-device-detect';

//regex is implemented in mobile only as we are not using the datepicker to set de date birth
const dateFormat = new RegExp(
    isMobile ? /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/ : /(?:)/
);
const schema = yup
    .object({
        firstName: yup.string().required('This field is required'),
        lastName: yup.string().required('This field is required'),
        birthDate: yup.string().required('This field is required').matches(dateFormat),
        memberId: yup
            .string()
            .length(11, 'Member ID must have 11 characters')
            .required('This field is required'),
        hasAgreedToTerms: yup
            .bool()
            .oneOf([true], 'Field must be checked')
            .required(),
    })
    .required();

interface MemberVerificationProps {
    onComplete?: (any) => void;
    state: any;
}
const ExistingMemberMessage = ({ loginLink, passwordLink }) => {
    return (
        <p>
            The member ID you entered is already registered to an existing
            account. Please go back to the{' '}
            <a
                href={loginLink ?? ''}
                style={{ color: '#0876AB', fontWeight: 'bold' }}
            >
                Login Page
            </a>{' '}
            to log in, or click on{' '}
            <a
                href={passwordLink ?? ''}
                style={{ color: '#0876AB', fontWeight: 'bold' }}
            >
                Forgot Password
            </a>{' '}
            if you need to change your password. If you believe you are
            receiving this message in error, please contact us at{' '}
            <strong>1-833-242-2223 (TTY 711)</strong>.
        </p>
    );
};
const MemberIdTooltipContent = () => {
    return (
        <div className="row tooltip-member-verification">
            <div id="memberID" className="col-7">
                <MemberIdCardImg />
            </div>
            <div className="col-5 mt-2 tooltip-memberId-message">
                <span>{TOOLTIP_REGISTER_MESSAGES.MEMBER_ID}</span>
            </div>
        </div>
    );
};
const MemberVerification = ({
    onComplete,
    state,
}: Partial<MemberVerificationProps>) => {
    const defaultValues = {
        firstName: state?.firstName ?? '',
        lastName: state?.lastName ?? '',
        birthDate: state?.birthDate ?? '',
        memberId: state?.memberId ?? '',
        hasAgreedToTerms: state?.hasAgreedToTerms ?? false,
    };
    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        defaultValues: defaultValues,
        mode: 'all',
        resolver: yupResolver(schema),
    });
    const { externalLinks } = useConfigContext();
    const [responseError, setResponseError] = useState<ResponseError>();
    const [showError, setShowError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = (data) => {
        setIsLoading(true);
        setShowError(false);

        validateMemberInfoAsync(data)
            .then((response: RegistrationResponse) => {
                if (response.isSuccess) {
                    onComplete && onComplete(data);
                } else {
                    const errorMessage = getAccountErrorMessage(response.code);
                    setResponseError({
                        message: errorMessage,
                        code: response.code,
                    });
                    setShowError(true);
                }
            })
            .catch((err) => {
                setShowError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div id="member-verification">
            <h1 className="section-header text-center">
                Register your member account
            </h1>
            <hr />
            {responseError?.code === ErrorCode.NO_MATCH && (
                <FailureMessage
                    message={responseError.message}
                    isOpen={showError}
                    toggle={() => setShowError(false)}
                />
            )}
            <h2 className="sub-header initial-header">
                First, let's verify a few things:
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                        <TextInput
                            label="First Name"
                            placeholder="Enter First Name"
                            errors={errors}
                            {...field}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="lastName"
                    render={({ field }) => (
                        <TextInput
                            label="Last Name"
                            placeholder="Enter Last Name"
                            errors={errors}
                            {...field}
                            tooltipContent={
                                <div className="tooltip-last-name-message">
                                    <span>
                                        {TOOLTIP_REGISTER_MESSAGES.LAST_NAME}
                                    </span>
                                </div>
                            }
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="birthDate"
                    render={({ field }) => (
                        <DatePicker
                            label="Date of Birth"
                            placeholder="mm/dd/yyyy"
                            errors={errors}
                            max="9999-12-31"
                            required
                            isMobile={isMobile}
                            {...field}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="memberId"
                    render={({ field }) => (
                        <TextInput
                            label="Member ID"
                            placeholder="00000000000"
                            errors={errors}
                            {...field}
                            tooltipContent={<MemberIdTooltipContent />}
                            tooltipMaxWidth={600}
                        />
                    )}
                />
                {responseError?.code === ErrorCode.MEMBERID_EXISTS && (
                    <FailureMessage
                        message={
                            <ExistingMemberMessage
                                loginLink={externalLinks?.memberLoginDirect}
                                passwordLink={externalLinks?.forgotPassword}
                            />
                        }
                        isOpen={showError}
                        toggle={() => setShowError(false)}
                    />
                )}
                <div className="d-flex flex-column align-items-center ">
                    <Controller
                        control={control}
                        name="hasAgreedToTerms"
                        render={({ field }) => (
                            <Checkbox
                                label={
                                    <span className="attestation-label">
                                        I agree to the{' '}
                                        <a
                                            href={externalLinks?.termsOfUse}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Terms of Service
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href={externalLinks?.privacyPolicy}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </span>
                                }
                                {...field}
                            />
                        )}
                    />

                    <Button
                        type="submit"
                        disabled={!isValid || isLoading}
                        className="next-button w-50"
                        color="primary"
                    >
                        Next
                        {isLoading && <Spinner size="sm" color="light" />}
                    </Button>
                </div>
            </form>
        </div>
    );
};
export default MemberVerification;
