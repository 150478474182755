import { useConfigContext } from 'contexts/ConfigContext';
import React from 'react';
import { Link } from 'react-router-dom';
import './Login.scss';

const logo = require('../../assets/img/AHP_Logo_Login.png');

const LoginTemplate = () => {
    const { externalLinks } = useConfigContext();
    return (
        <div id="login-container">
            <img src={logo} alt="AHP Logo" className="my-2" />
            <div>
                <h1 className="section-header text-center">
                    Login to your Member Account
                </h1>
                <hr />
                <div
                    id="api"
                    data-name="Unified"
                    style={{ width: '100%', paddingTop: '20px' }}
                />
                <div
                    id="action-container"
                    className="d-flex justify-content-between align-items-end"
                >
                    <p className="text-left">
                        Don't have an account?
                        <br />
                        <Link to="/account-register">
                            Click here to register
                        </Link>
                    </p>
                    <p className="text-right">
                        <Link to="/forgot-password">Forgot password</Link>
                    </p>
                </div>
                <p id="notice-container" className="text-center">
                    By signing into your account you are agreeing to our{' '}
                    <a
                        href={externalLinks?.termsOfUse}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                        href={externalLinks?.privacyPolicy}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                </p>
            </div>
        </div>
    );
};

export default LoginTemplate;
