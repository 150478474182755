const URL_ENDPOINTS = {
    GET_CONFIG: 'api/Config',
};
const headers = { 'Content-Type': 'application/json' };

const getAppLinkConfigs = async () => {
    const response = fetch(URL_ENDPOINTS.GET_CONFIG, {
        method: 'GET',
        headers,
    }).then((response) => response.json());
    return response;
};

export { getAppLinkConfigs };
