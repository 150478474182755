import { verifyMemberEmailAsync } from 'api/registration';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import FormContainer from './common/FormContainer';
import ActivationVerification from './pages/ActivationVerification';
import ActivationNotification from './pages/ActivationNotification';
import Confirmation from './pages/Confirmation';
import './Registration.scss';

enum STEPS {
    VERIFICATION,
    NOTIFICATION,
    CONFIRMATION,
}

const ActivationLink = () => {
    const { verificationToken } = useParams();
    const [formState, setFormState] = useState({ page: STEPS.VERIFICATION });
    const [loading, setLoading] = useState(false);
    const authorizeAccountAccess = (token: string) => {
        setLoading(true);
        verifyMemberEmailAsync(token)
            .then(({ isSuccess }) => {
                setFormState((prevState) => ({
                    ...prevState,
                    success: isSuccess,
                    page: STEPS.CONFIRMATION,
                }));
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setFormState((prevState) => ({
                    page: STEPS.VERIFICATION,
                }));
            });
    };
    useEffect(() => {
        verificationToken && authorizeAccountAccess(verificationToken);
    }, [verificationToken]);
    const CurrentPage = ({ ...rest }) => {
        switch (formState.page) {
            case STEPS.VERIFICATION:
                return (
                    <ActivationVerification
                        onComplete={(data) =>
                            setFormState((prevState) => ({
                                ...data,
                                ...prevState,
                                page: STEPS.NOTIFICATION,
                            }))
                        }
                        {...rest}
                    />
                );
            case STEPS.NOTIFICATION:
                return <ActivationNotification {...rest} />;
            case STEPS.CONFIRMATION:
                return <Confirmation {...rest} />;
        }
    };

    return (
        <div id="wizard-container">
            <FormContainer variant="white">
                {loading ? (
                    <Spinner color="secondary" />
                ) : (
                    <CurrentPage state={formState} />
                )}
            </FormContainer>
        </div>
    );
};

export default ActivationLink;
