import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useState } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types';
import HelpTooltip from '../common/HelpTooltip';
import { FormGroup, Label, Input, FormFeedback, InputGroup } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import './index.scss';

interface TextInputProps {
    name: string;
    placeholder: string;
    label: ReactNode;
    type: InputType;
    errors: FieldErrors;
    tooltipContent: string | ReactNode;
    tooltipMaxWidth?: number;
    invalid?: boolean;
}
const TextInput = ({
    name,
    placeholder,
    label,
    type = 'text',
    errors,
    tooltipContent,
    tooltipMaxWidth,
    invalid = false,
    ...rest
}: Partial<TextInputProps>) => {
    const [showText, setShowText] = useState<boolean>(false);

    const id = `text-input-${name}`;

    const handleShowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowText((prev) => !prev);
    };
    return (
        <FormGroup>
            <Label for={id}>{label}</Label>{' '}
            {tooltipContent && (
                <HelpTooltip
                    content={tooltipContent}
                    maxWidth={tooltipMaxWidth}
                />
            )}
            <InputGroup>
                <div className="input-wrapper">
                    <Input
                        id={id}
                        invalid={invalid || !!errors?.[`${name}`]?.message}
                        name={name}
                        placeholder={placeholder}
                        type={type === 'password' && showText ? 'text' : type}
                        {...rest}
                    />
                    {type === 'password' && (
                        <button
                            className="show-button"
                            onClick={handleShowClick}
                        >
                            <span>{showText ? 'Hide' : 'Show'} </span>
                            <FontAwesomeIcon
                                icon={showText ? faEyeSlash : faEye}
                            />
                        </button>
                    )}
                </div>
                <FormFeedback>
                    {errors &&
                        (errors[`${name}`]?.message?.toString() ??
                            'This field is required')}
                </FormFeedback>
            </InputGroup>
        </FormGroup>
    );
};
export default TextInput;
