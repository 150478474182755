import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Spinner } from 'reactstrap';
import { TextInput } from '../inputs';
import './index.scss';
import { requestNewVerificationLink } from 'api/account';
import { yupEmailValidation } from '../util/validation';
import FailureMessage, { FailureMessageProps } from '../common/FailureMessage';

const schema = yup.object({
    email: yupEmailValidation,
});

const ActivationVerification = ({ onComplete }) => {
    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        defaultValues: { email: '' },
        mode: 'all',
        resolver: yupResolver(schema),
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [failure, setFailure] = useState<FailureMessageProps>({
        message: '',
        isOpen: false,
    });
    const onSubmit = async (data) => {
        setIsLoading(true);
        requestNewVerificationLink(data)
            .then(({ isSuccess, errorMessage }) => {
                if (isSuccess) {
                    onComplete && onComplete(data);
                } else {
                    setFailure({ message: errorMessage, isOpen: true });
                }
            })
            .catch()
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <div>
            <h1 className="section-header text-center">
                Request New Activation Link
            </h1>
            <hr />
            <FailureMessage
                {...failure}
                toggle={() => setFailure({ isOpen: false })}
            />
            <div
                id="action-container"
                className="d-flex justify-content-center"
            >
                <p className="text-center">
                    Please enter your registered Email Address and we will send
                    a new activation link to your email.
                </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                        <TextInput
                            label="Enter Registered Email Address"
                            type="email"
                            errors={errors}
                            {...field}
                        />
                    )}
                />
                <div className="d-flex justify-content-center w-100">
                    <Button
                        type="submit"
                        disabled={!isValid || isLoading}
                        className="next-button w-25"
                        color="primary"
                    >
                        Submit{' '}
                        {isLoading && <Spinner size="sm" color="light" />}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ActivationVerification;
