import React, { ReactNode } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import './index.scss';

interface CheckboxProps {
    name: string;
    label: string | ReactNode;
    value: boolean;
}

const Checkbox = ({ name, label, value, ...rest }: Partial<CheckboxProps>) => {
    return (
        <FormGroup check>
            <Input type="checkbox" name={name} checked={value} {...rest} />{' '}
            <Label check>{label}</Label>
        </FormGroup>
    );
};
export default Checkbox;
