import ForgotPassword from 'components/form/ForgotPassword';
import RegistrationForm from 'components/form/Registration';
import ActivationLink from 'components/form/ActivationLink';
import Layout from 'components/Layout';
import LoginTemplate from 'components/templates/Login';
import { WithConfigContext } from 'contexts/ConfigContext';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './assets/css/global.scss';

const App = () => {
    return (
        <div>
            <WithConfigContext>
                <Layout>
                    <Routes>
                        <Route path="/" element={<LoginTemplate />} />
                        <Route
                            path="/account-register/:createToken?"
                            element={<RegistrationForm />}
                        />
                        <Route
                            path="/reset-password/:resetToken?"
                            element={<ForgotPassword />}
                        />
                        <Route
                            path="/forgot-password/:resetToken?"
                            element={<ForgotPassword />}
                        />
                        <Route
                            path="/activation-link/:verificationToken?"
                            element={<ActivationLink />}
                        />
                    </Routes>
                </Layout>
            </WithConfigContext>
        </div>
    );
};
export default App;
