import Condition from 'types/Condition';

const initialConditions: Condition[] = [
    {
        name: 'length',
        label: 'Between 8 to 16 characters',
        checked: false,
    },
    {
        name: 'lowercase',
        label: 'At least one lowercase letter',
        checked: false,
    },
    {
        name: 'uppercase',
        label: 'At least one uppercase letter',
        checked: false,
    },
    {
        name: 'number',
        label: 'At least one number (0-9)',
        checked: false,
    },
    {
        name: 'symbol',
        label: 'At least one symbol: ! @ # $ % ^ & * - _ + = [ ] { } | \\ : \' , ? / ` ~ " ( ) ; .',
        checked: false,
    },
];

export { initialConditions };
