import React from 'react';
import './index.scss';

interface ResetNotificationProps {
    onComplete?: (any) => void;
    state: any;
}
const ResetNotification = ({ state }: Partial<ResetNotificationProps>) => {
    return (
        <div>
            <h1 className="section-header text-center">
                Reset Account Password
            </h1>
            <hr />
            <div
                id="action-container"
                className="d-flex flex-column justify-content-center"
            >
                {state.errorMessage ? (
                    <>
                        <p className="text-center">{state.errorMessage}</p>
                        <p className="text-center">
                            If you need additional assistance, please contact
                            your 24/7 ACCESS On-Demand Concierge at
                            1-833-242-2223 (TTY: 711)
                        </p>
                    </>
                ) : (
                    <>
                        <p className="text-center">
                            A reset password link was sent to your email. Please
                            check your email {state.email} for instructions on
                            how to change your password.
                        </p>
                        <p className="danger-text text-center">
                            If you have submitted multiple password reset
                            requests, please use the link provided in the most
                            recent email sent to your inbox.
                        </p>
                        <p className="text-center">
                            If you can't find the email in your inbox, please
                            check your spam folder. If you need additional
                            assistance, please contact your 24/7 ACCESS
                            On-Demand Concierge at 1-833-242-2223 (TTY: 711)
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default ResetNotification;
