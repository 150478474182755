import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
interface HelpTooltipProps {
    content: string | ReactNode;
    maxWidth?: number;
}
const HelpTooltip = ({
    content,
    maxWidth = 300,
}: Partial<HelpTooltipProps>) => {
    const CustomWidthTooltip = styled(
        ({ className, ...props }: TooltipProps) => (
            <Tooltip
                {...props}
                placement="bottom-start"
                classes={{ popper: className }}
            />
        )
    )({
        [`& .${tooltipClasses.arrow}`]: {
            color: '#3d3d3d',
        },
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: maxWidth,
            backgroundColor: '#3d3d3d',
        },
    });
    return (
        <CustomWidthTooltip title={content} arrow>
            <HelpIcon color="info" />
        </CustomWidthTooltip>
    );
};
export default HelpTooltip;
