import React, { useState, useEffect } from 'react';
import {
    useForm,
    FormProvider,
    useFormContext,
    FieldValues,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import './index.scss';
import { resetPasswordAsync } from 'api/account';
import { passwordSchema } from '../util/validation';
import { useParams } from 'react-router-dom';
import CreatePassword from '../common/CreatePassword';
import { Button, Spinner } from 'reactstrap';
import { initialConditions } from 'constants/conditions';

type ResetPasswordValues = {
    password: string;
    confirmedPassword: string;
};
interface ResetPasswordProps {
    onComplete?: (data: FieldValues) => void;
}

const ResetPassword = ({ onComplete }: ResetPasswordProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { resetToken } = useParams();
    const { handleSubmit, formState, trigger } = useFormContext();

    const onSubmit = async (data) => {
        setIsLoading(true);
        data.token = resetToken;
        resetPasswordAsync(data)
            .then(({ isSuccess, errorMessage }) => {
                if (isSuccess) {
                    onComplete && onComplete(data);
                }
            })
            .catch()
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        trigger();
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h1 className="section-header text-center">
                Reset Account Password
            </h1>
            <hr />
            <div
                id="action-container"
                className="d-flex justify-content-center"
            >
                <p className="text-center">Please enter your new password.</p>
            </div>
            <CreatePassword
                initialConditions={initialConditions}
                formState={formState}
            />
            <div className="d-flex justify-content-center w-100">
                <Button
                    type="submit"
                    className="next-button"
                    color="primary"
                    disabled={isLoading}
                >
                    Reset Password{' '}
                    {isLoading && <Spinner size="sm" color="light" />}
                </Button>
            </div>
        </form>
    );
};

const ResetPasswordWrapper = ({ onComplete }: ResetPasswordProps) => {
    const methods = useForm<ResetPasswordValues>({
        defaultValues: { password: '', confirmedPassword: '' },
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(passwordSchema, { abortEarly: false }),
    });

    return (
        <FormProvider {...methods}>
            <ResetPassword onComplete={onComplete} />
        </FormProvider>
    );
};

export default ResetPasswordWrapper;
