import { validateResetTokenAsync } from 'api/account';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import FormContainer from './common/FormContainer';
import ResetConfirmation from './pages/ResetConfirmation';
import ResetNotification from './pages/ResetNotification';
import ResetPassword from './pages/ResetPassword';
import ResetVerification from './pages/ResetVerification';
import './Registration.scss';
enum STEPS {
    VERIFICATION,
    NOTIFICATION,
    RESET,
    CONFIRMATION,
}

interface ForgotPasswordState {
    page: STEPS;
    errorMessage?: string;
}

const ForgotPassword = () => {
    const { resetToken } = useParams();
    const [formState, setFormState] = useState<ForgotPasswordState>({
        page: STEPS.VERIFICATION,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const authorizePasswordReset = (token: string) => {
        setLoading(true);
        validateResetTokenAsync(token)
            .then(({ isSuccess, errorMessage }) => {
                isSuccess
                    ? setFormState((prevState) => ({
                          ...prevState,
                          page: STEPS.RESET,
                      }))
                    : setFormState({
                          page: STEPS.NOTIFICATION,
                          errorMessage,
                      });
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setFormState(() => ({
                    page: STEPS.NOTIFICATION,
                    errorMessage:
                        'Something went wrong when validating the reset token.',
                }));
            });
    };
    useEffect(() => {
        resetToken && authorizePasswordReset(resetToken);
    }, [resetToken]);
    const CurrentPage = ({ ...rest }) => {
        switch (formState.page) {
            case STEPS.VERIFICATION:
                return (
                    <ResetVerification
                        onComplete={(data) =>
                            setFormState((prevState) => ({
                                ...data,
                                ...prevState,
                                page: STEPS.NOTIFICATION,
                            }))
                        }
                        {...rest}
                    />
                );
            case STEPS.NOTIFICATION:
                return <ResetNotification {...rest} />;
            case STEPS.RESET:
                return (
                    <ResetPassword
                        onComplete={(data) =>
                            setFormState((prevState) => ({
                                ...data,
                                ...prevState,
                                page: STEPS.CONFIRMATION,
                            }))
                        }
                        {...rest}
                    />
                );
            case STEPS.CONFIRMATION:
                return <ResetConfirmation {...rest} />;
            default:
                return null;
        }
    };

    return (
        <div id="wizard-container">
            <FormContainer variant="white">
                {loading ? (
                    <Spinner color="secondary" />
                ) : (
                    <CurrentPage state={formState} />
                )}
            </FormContainer>
        </div>
    );
};
export default ForgotPassword;
