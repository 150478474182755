import { getAppLinkConfigs } from 'api/configuration';
import React, { useContext, useEffect, useState, ReactNode } from 'react';
import { Spinner, Alert } from 'reactstrap';
import ConfigValues from 'types/ConfigValues';
import './ConfigContext.scss';

const ConfigContext = React.createContext<ConfigValues>({});

const useConfigContext = () => {
    const configValues = useContext(ConfigContext);
    return configValues;
};
interface ConfigContextProps {
    children?: ReactNode;
}
const WithConfigContext = ({ children }: ConfigContextProps) => {
    const [configValues, setConfigValues] = useState<Record<string, unknown>>();

    useEffect(() => {
        getAppLinkConfigs()
            .then((linkSet) => {
                setConfigValues(linkSet);
            })
            .catch((err) => {
                throw err;
            });
    }, []);
    if (!configValues) {
        return (
            <div className="pre-loading">
                <Spinner animation="border" variant="secondary" />
            </div>
        );
    } else if ((configValues?.status as number) >= 400) {
        return (
            <div className="pre-loading text-left">
                <Alert color="danger" className="mt-5">
                    There was a network error.
                    <br />
                    Please try refreshing or contact the system administrator.
                </Alert>
            </div>
        );
    }
    return (
        <ConfigContext.Provider value={configValues}>
            {children}
        </ConfigContext.Provider>
    );
};
export { WithConfigContext, useConfigContext };
