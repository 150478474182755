import { useConfigContext } from 'contexts/ConfigContext';
import React from 'react';
import { Button } from 'reactstrap';
import './index.scss';
interface ConfirmationProps {
    state: any;
}
const Confirmation = ({ state }: Partial<ConfirmationProps>) => {
    const { externalLinks } = useConfigContext();
    const onExit = () =>
        (window.location.href = externalLinks?.memberLoginDirect ?? '');
    if (state?.success) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column">
                <h1 className="section-header text-center">Congratulations!</h1>
                <hr />
                <h2 className="sub-header initial-header">
                    <p>
                        Thank you for registering! Your Alignment Health account
                        is now active. Log in to your Member Portal below.
                    </p>
                </h2>
                <Button
                    className="next-button w-50"
                    color="primary"
                    onClick={onExit}
                >
                    Login
                </Button>
            </div>
        );
    }
    return (
        <div className="d-flex justify-content-center align-items-center flex-column">
            <h1 className="section-header text-center">Oh no!</h1>
            <hr />
            <h2 className="sub-header initial-header">
                <p>Failed to verify the email. Please try again.</p>
            </h2>
            <Button
                className="next-button w-50"
                color="primary"
                onClick={onExit}
            >
                Go Back
            </Button>
        </div>
    );
};
export default Confirmation;
